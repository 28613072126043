.upload-btn {
  width: 5rem;
}
.upload-btn .el-upload {
  width: 5rem;
  height: auto !important;
  border: none !important;
}
.upload-btndrag {
  width: 15rem;
}
.upload-btndrag .el-upload {
  width: 15rem;
}
