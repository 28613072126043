








































































.upload-btn {
  width: 5rem;
  .el-upload {
    width: 5rem;
    height: auto !important;
    border: none !important;
  }
}
.upload-btndrag {
  width: 15rem;
  .el-upload {
      width: 15rem;
  }
}
