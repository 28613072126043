.sendCustomerLoding /deep/ .el-dialog__body i {
  font-size: 55px;
  color: #67c23a;
  display: block;
  text-align: center;
  margin-top: 20px;
}
.sendCustomerLoding /deep/ .el-dialog__body p {
  text-align: center;
  font-size: 16px;
  margin: 40px 0 40px 0;
}
.sendCustomerLoding /deep/ .el-dialog__body .el-button {
  display: block;
  width: 100px;
  margin: auto;
}
